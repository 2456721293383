

















































import { Component, Vue } from "vue-property-decorator";
import Carousel from "@/components/for-auth-view/Carousel.vue";
import XButton from "@/components/SimpleButton.vue";
import ButtonWithMessage
  from "@/components/for-auth-view/ButtonWithMessage.vue";

@Component({
  components: {
    Carousel,
    XButton,
    ButtonWithMessage,
  },
})
class Auth extends Vue {
  get URLs() {
    const stackRef = "https://stack-it.ru";
    const appHelpRef = process.env && process.env.VUE_APP_HELP_REF;

    return {
      stack: stackRef, appHelp: appHelpRef || stackRef,
    };
  }

  get isSignUpPage(): boolean {
    return this.$route.name === "signUp";
  }
}

export default Auth;
